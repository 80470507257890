import {collection, getDocs} from "firebase/firestore";
import {db} from "@/firebase";
import {parksData} from "../../json/parks";
import {getStates, slugify} from "@/lib/util";

export async function getAllEvents() {
    const response = await getDocs(collection(db, "events"))
    let events = JSON.parse(JSON.stringify(response.docs))

    let allEvents = [];
    events.map((event) => {
        allEvents.push(event._document.data.value.mapValue.fields)
    })

    return allEvents
}

export function getParkNameSelectOptions() {
    const parkNameOptions = []
    parksData && parksData.forEach((park) => parkNameOptions.push({'value': slugify(park.fullName), 'label': park.fullName}))
    return parkNameOptions
}

export function getParkNames() {
    let parkNames = []
    parksData.map((park) => parkNames.push(park.fullName))
    return parkNames
}

export function getParkFromParkCode(parkCode) {
    return parksData.filter((park) => park.parkCode === parkCode)
}

export function statesAndParkCodes() {
    let parkStatesAndCode = []
    parksData.map((park) => parkStatesAndCode.push({'parkCode': park.parkCode, 'state': park.states }))
    return parkStatesAndCode
}

export function getStateAbbr(stateFullName) {
    const states = getStates()
    let statesWithLabel = states.filter(state => state.label === stateFullName)
    return statesWithLabel[0].value
}

export function getAllParkCodesForState(stateAbbr) {
    const parkStatesAndParkCodes = statesAndParkCodes()
    let parkCodes = []
    parkStatesAndParkCodes.map((item) => item.state.includes(stateAbbr) ? parkCodes.push(item.parkCode) : null)
    return parkCodes
}

export function getEventsAndSortByDate(events) {
    // Sort events by dateStart.
    events.sort((a, z) => new Date(a.dateStart) - new Date(z.dateStart));

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize the time to midnight for today

    // Filter out events where the end date is past from today.
    return events.filter((event) => {
        const eventEndDate = new Date(event.dateEnd);
        eventEndDate.setHours(0, 0, 0, 0); // Normalize the time to midnight for event end date
        return eventEndDate >= today;
    });
}